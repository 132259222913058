<template>
	<div class="sms-wrap">
	<div class="sms-header">
		<div class="title">
		ReapPay <span class="white1">Payments</span>
		</div>
		<div>
		<div class="sms-content" ref="refReceipt" id="divReceipt">
			<div class="sms-receipt">
			<div style="width:340px; margin-bottom: 10px; padding: 15px 30px 10px 30px; display: inline-block;"
				:style="{ backgroundImage: 'url(' + require('@/assets/img/receipt-background.png') + ')'}">
				<div style="text-align: center">
					<span style="margin-right: 8.5px; color: #18CAA6; font-size: 22px;">ReapPay</span>
					<span style="margin-left: 8.5px; color: #808080; font-size: 16px;">Payments</span>
				</div>
				<hr class="divider">
				<div class="receipt-title">
					영수증
				</div>
				<tbody>
				<tr>
					<td>승인번호</td>
					<td>{{ item.appNo }}</td>
				</tr>
				<tr
					v-if="false"
				>
					<td>카드종류</td>
					<td>{{ item.cardCate }}</td>
				</tr>
				<tr>
					<td>카드번호</td>
					<td>{{ item.cardNum | makeCardNumber2 }}</td>
				</tr>
				<tr>
					<td>거래종류</td>
					<td>개인(신용)</td>
				</tr>
				<tr>
					<td>할부</td>
					<td>{{ getCodeName('installment', item.installment) }}</td>
				</tr>
				<tr>
					<td>결제일자</td>
					<td>{{ item.tranDt | transDate }}	{{ item.tranTm | transTime }}</td>
				</tr>
				<tr>
					<td>상품명</td>
					<td>{{ item.productNm }}</td>
				</tr>
				<tr>
					<td>공급가액</td>
					<td>{{ item.taxAmt | makeComma }}원</td>
				</tr>
				<tr>
					<td>부가세액</td>
					<td>{{ item.vatAmt | makeComma }}원</td>
				</tr>
				<tr>
					<td>합계</td>
					<td style="color: #00BFA5; font-size: 15px;">{{ item.totAmt | makeComma }} 원</td>
				</tr>
				</tbody>
				<hr class="divider">
				<div class="receipt-title">
				판매자 정보
				</div>
				<tbody>
				<tr>
					<td>판매자</td>
					<td>{{ item.contactUserNm }}</td>
				</tr>
				<tr>
					<td>대표자명</td>
					<td>{{ item.compCeoNm }}</td>
				</tr>
				<tr>
					<td>사업자번호</td>
					<td>{{ item.bizNo }}</td>
				</tr>
				<tr>
					<td>연락처</td>
					<td>{{ item.contactPhoneNo | makePhoneNumber }}</td>
				</tr>
				<tr>
					<td>주소</td>
					<td>{{ item.compAddr1 }} {{ item.compAddr2}}</td>
				</tr>
				</tbody>
				<div
					v-if="false"
					style="text-align: center; margin-top: 34px;" data-html2canvas-ignore="true">
					<v-btn
					rounded dark large depressed
					color="#00BFA5"
					style="width: 235px; font-size: 16px; font-weight: bold"
					@click="imgDownload"
				>영수증 저장</v-btn>
<!--				<img :src="output">-->
<!--				<a id="receiptTarget" style="display:none;"></a>-->
				</div>
			</div>
				<div>
					<v-btn
						block
						class="default"
						@click="result"
					>닫기</v-btn>
				</div>
			</div>
		</div>
		</div>
	</div>
	<div class="sms-footer">
		Copyrights 2020 REAPPAY PAYMENTS. ALL Rights reserved.
	</div>
	</div>
</template>

<script>

	import * as htmlToImage from "html-to-image";

	export default{
		name: 'PaymentSmsReceipt'
		,props: ['callBack']
		,components: {}
		,data: function(){
			return {
				program: {
					name: 'PaymentSmsReceipt'
					,not_title: true
					,not_navigation: true
				}
				,item:{}
				,output: null
			}
		}
		,computed: {}
		,methods: {
			axios: function(call){
				this.$emit('axios', call)
			}
			,getData: async function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/pay/sms/receipt'
					,request_params: {
						requestId: this.$route.params.authorize
						,requestKey: this.$route.params.index
						,tranSeq: this.$route.params.tranSeq
					}
					,callBack: 'setItem'
				})
			}
			,setItem: function(call){
				if(call.result && call.items.content){
					this.item = call.items.content
				}else{
					this.$emit('alertMsg', 'error', '결제정보 호출 중 오류가 발생했습니다. 관리자에게 문의하세요.')
				}
			}
			,getCodeName: function (codeType, codeValue){
				if(codeType=='installment'){
					switch (codeValue) {
					case '00':
						return '일시불'
					case '01':
						return '1개월'
					case '02':
						return '2개월'
					case '03':
						return '3개월'
					case '04':
						return '4개월'
					case '05':
						return '5개월'
					case '06':
						return '6개월'
					}
				}
			}
			,imgDownload: async function () {
				/* html to image 라이브러리 test 코드 */
				let node = document.getElementById('divReceipt')

				htmlToImage.toCanvas(node)
					.then(function (canvas) {
						// let img = new Image();
						// img.src = dataUrl;
						// document.body.appendChild(img);
						let link = document.createElement('a')
						link.download = 'receipt.jpg'
						link.href = canvas.toDataURL("image/jpeg").replaceAll("data:image/png;base64,", "image/octet-stream")
						link.click()
					})
					.catch(function (error) {
						console.error('oops, something went wrong!', error);
				});
			}
			,result: function(){
				this.$router.push('/Payment/Sms/' + this.$route.params.authorize + '/' + this.$route.params.index)
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
		,watch: {
			callBack: {
			deep: true
			,handler: function(call){
				if(call.name == 'setItem'){
				this.setItem(call)
				}
			}
			}
		}
	}
</script>
<style>
.sms-wrap { display: flex; flex-direction: column; height: 100%; background-color: #eee;}

.sms-header { text-align: center; background: #153241;}
.sms-header .title {padding: 20px 10px; color: #00BFA5 !important; font-size: 1.8em !important;}
.sms-header .white1 {background-color: #15324e !important; color: white !important; font-size: 0.7em !important;}

.sms-receipt { width: 100%; padding: 10px; background-color: #00BFA5; border: 1px solid #ddd; text-align: center}
.receipt-title { padding-left: 10px; text-align: left; margin: 4px 0px; font-weight: bold; }

.sms-footer { maring-top: auto;	text-align: center; padding: 20px; font-size: 0.6em;}

.divider { border-color: #18CAA6; margin-top: 15px; margin-bottom: 15px; }

td {padding: 5px 10px; font-font: 'Roboto'; font-size: 13px; text-align: left; }
td:nth-child(1) {width: 85px; font-weight: bold;}
td:nth-child(2) {word-break: break-all;}
</style>
